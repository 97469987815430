import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "How will it be different from my regular school english class?",
    answer:
      "Unlike regular school English classes, this program won’t delve into in-depth grammar rules but will focus more on speaking skills needed for fluency, offering smaller class sizes for personalized attention and prioritizing practical sessions over theoretical lessons.",
  },
  {
      question: 'What is the duration of the "Curious Starters" course?',
      answer:
        "5 Months comprising of 40 direct classes plus 20 online / offline practice and fine tuning sessions.",
    },
    {
        question: '"What is the duration of the "High Flyers" course?',
        answer:
          "4 Months comprising of 32 direct classes plus 16 online / offline practice and fine tuning sessions.",
    },
    {
        question: "How my classes will be? what will be focused?",
        answer:
          "You will be trained to speak more fluently in various situations. The program will enhance your skills in effective body language, voice modulation, and the use of pauses. You’ll also expand your vocabulary, allowing you to express yourself confidently and comfortably, without hesitation.",
    },

    {
      question: "What support will I get after the course completion?",
      answer:
        "You’ll have access to English language clubs through us, where you can continue to present and practice your skills. Additionally, we offer support in clearing your academic doubts via WhatsApp chats for a year.",
  },
  {
    question: "Do You Provide Training for Exams Like IELTS?",
    answer:
      "Yes, through online we provide one-on-one short time courses for IELTS by master trainers trained by the British Council.",
},
{
  question: "What is I Can Speak Pro which is mentioned in footer of this website?",
  answer:
    "its a similar kind of program for young adults aged above 15 years to 22 years, which will be started at a different location in the future.",
},

  // More questions...
]

export default function Faqex() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
